import * as React from 'react';
import { Heading } from 'paypo-ui/components';

import Seo from '../../components/seo';
import CookieModal from '../../components/organisms/CookieModal';

import { COOKIE_MODAL_CONFIG } from '../../content_config_start_ro';

const NotFoundPage = () => (
  <div>
    <Seo title="404: Not Found" />

    <Heading variant='H1'>
      404: Page not found
    </Heading>
  
    <CookieModal
      config={ COOKIE_MODAL_CONFIG }
    />
  </div>
);

export default NotFoundPage;
